import { Button, Textarea, Tooltip } from '@nextui-org/react'
import { FileMusic } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { toast } from '~/components/Toast'
import useStrings from '~/hooks/useStrings'
import { apiGet, extractApiError } from '~/lib/apiUtils.client'
import { NextExerciseResponse } from '~/routes/api.nextExercise'

const ItemExercise = () => {
  const { menuNextExercise, menuNextExerciseTooltip } = useStrings()
  const navigate = useNavigate()

  const handleClick = async () => {
    const response = await apiGet('/api/nextExercise')
    if (!response.ok) {
      const err = await extractApiError(response)
      toast({
        type: 'error',
        content: err,
      })
    } else {
      const { exerciseDrill } = (await response.json()) as NextExerciseResponse
      console.log(`received exercise drill`, exerciseDrill)
      if (exerciseDrill) {
        navigate(`/exercise/${exerciseDrill.drill.id}`)
      } else {
        toast({
          type: 'error',
          content: `No more exercises remaining in this program`,
        })
      }
    }
  }

  return (
    <Tooltip content={menuNextExerciseTooltip} placement="bottom" showArrow>
      <Button
        onPress={handleClick}
        className={`flex items-center justify-center w-full p-3`}
      >
        <span className={`flex items-center transition-all duration-300 mr-3`}>
          <FileMusic size={24} className="text-current" />
        </span>
        <Textarea size="md" className="whitespace-nowrap">
          {menuNextExercise}
        </Textarea>
      </Button>
    </Tooltip>
  )
}

export default ItemExercise
