import {
  Button,
  Input,
  Kbd,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  Textarea,
} from '@nextui-org/react'
import { link as linkStyles } from '@nextui-org/theme'
import clsx from 'clsx'
import { useFeature } from 'flagged'
import { Piano, Search } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useLogout from '~/hooks/useLogout'
import useStrings from '~/hooks/useStrings'
import { useUserContext } from '~/hooks/useUserContext'
import { toast } from '../Toast'
import ItemExercise from './menuItems/itemExercise'

interface NavItem {
  label: string
  admin: boolean
  href: string
}

export const NavigationBar = () => {
  const navigate = useNavigate()
  const { userData } = useUserContext()
  const [isAdmin, setIsAdmin] = useState(false)
  const [navItems, setNavItems] = useState<NavItem[]>([])
  const {
    menuListDrills,
    menuListArticles,
    menuLogin,
    menuLogout,
    logoutError,
  } = useStrings()
  const logout = useLogout()
  const hasV1 = useFeature('v1')

  const handleLogout = async () => {
    const result = await logout()
    if (result.error) {
      toast({
        type: 'error',
        content: logoutError,
      })
    }
  }

  const allNavItems = [
    {
      label: 'Home',
      admin: false,
      href: '/',
    },
    {
      label: menuListArticles,
      admin: true,
      href: '/articles',
    },
    {
      label: menuListDrills,
      admin: true,
      href: '/drills',
    },
  ]

  useEffect(() => {
    setIsAdmin(userData?.isAdmin || false)
    setNavItems(
      allNavItems.filter(i => (!i.admin && hasV1) || userData?.isAdmin),
    )
  }, [userData, hasV1])

  const searchInput = (
    <Input
      aria-label="Search"
      classNames={{
        inputWrapper: 'bg-default-100',
        input: 'text-sm',
      }}
      endContent={
        <Kbd className="hidden lg:inline-block" keys={['command']}>
          K
        </Kbd>
      }
      labelPlacement="outside"
      placeholder="Search..."
      startContent={
        <Search className="text-base text-default-400 pointer-events-none flex-shrink-0" />
      }
      type="search"
    />
  )

  return (
    <Navbar maxWidth="xl" position="sticky">
      <NavbarContent className="basis-1/5 sm:basis-full" justify="start">
        <NavbarBrand as="li" className="gap-3 max-w-fit">
          <Link
            className="flex justify-start items-center gap-1"
            onPress={() => navigate('/')}
          >
            <Piano size={44} strokeWidth={1} />
            <p className="font-bold text-inherit">My Piano Trainer</p>
          </Link>
        </NavbarBrand>
        <ul className="hidden lg:flex gap-4 justify-start ml-2">
          {navItems.map(item => (
            <NavbarItem key={item.href}>
              <Link
                className={clsx(
                  linkStyles({ color: 'foreground' }),
                  'data-[active=true]:text-primary data-[active=true]:font-medium',
                )}
                color="foreground"
                onPress={() => navigate(item.href)}
              >
                {item.label}
              </Link>
            </NavbarItem>
          ))}
        </ul>
      </NavbarContent>

      <NavbarContent
        className="hidden sm:flex basis-1/5 sm:basis-full"
        justify="end"
      >
        {hasV1 && (
          <NavbarItem className="hidden sm:flex gap-2">
            <ItemExercise />
          </NavbarItem>
        )}
        <NavbarItem className="hidden lg:flex">{searchInput}</NavbarItem>
        <NavbarItem>
          {userData?.email && (
            <Textarea
              size="md"
              className="whitespace-nowrap"
              data-testid="header-user-name"
            >
              {userData.email}
            </Textarea>
          )}
          {(!userData || !userData.email) && (
            <Button onPress={() => navigate('login')}>{menuLogin}</Button>
          )}
          {userData && userData.email && (
            <Button onPress={() => handleLogout}>{menuLogout}</Button>
          )}
        </NavbarItem>
      </NavbarContent>

      <NavbarMenu>
        {searchInput}
        <div className="mx-4 mt-2 flex flex-col gap-2">
          {navItems.map((item, index) => (
            <NavbarMenuItem key={`${item}-${index}`}>
              <Link
                color={
                  index === 2
                    ? 'primary'
                    : index === navItems.length - 1
                      ? 'danger'
                      : 'foreground'
                }
                onPress={() => navigate(item.href)}
                size="lg"
              >
                {item.label}
              </Link>
            </NavbarMenuItem>
          ))}
        </div>
      </NavbarMenu>
    </Navbar>
  )
}
