// hooks/useLogout.ts
import { useNavigate } from '@remix-run/react'
import Cookies from 'js-cookie'
import useStrings from '~/hooks/useStrings'
import { SUPABASE_COOKIE_NAME } from '~/lib/constants'
import type { HookActionResult } from './types'
import useSupabase from './useSupabase'

export default function useLogout() {
  const supabaseClient = useSupabase()
  const navigate = useNavigate()
  const { logoutError } = useStrings()

  const logout = async (): Promise<HookActionResult> => {
    const { error } = await supabaseClient.auth.signOut()
    if (error) {
      return {
        success: false,
        error: {
          message: logoutError,
        },
      }
    }

    Cookies.remove(SUPABASE_COOKIE_NAME)
    navigate('/login')
    return {
      success: true,
    }
  }

  return logout
}
